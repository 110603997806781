export const sliderItems = [
  {
    id: 1,
    img: "https://images.pexels.com/photos/5076516/pexels-photo-5076516.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "NOUVELLE OFFRES",
    desc: "",
    bg: "f5fafd",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/4068314/pexels-photo-4068314.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "PRODUIT DE QUALITÉ",
    desc: "",
    bg: "fcf1ed",
  },
  {
    id: 3,
    img: "https://images.pexels.com/photos/4968391/pexels-photo-4968391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "ACHETEZ EN TOUTE SÉCURITÉ",
    desc: "",
    bg: "fbf0f4",
  },
];

export const categories = [
  {
    id: 4,
    img: "https://images.pexels.com/photos/3965557/pexels-photo-3965557.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "MODE",
  },
  {
    id: 8,
    img: "https://images.pexels.com/photos/3985062/pexels-photo-3985062.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "ALIMENTATION",
  },
  {
    id: 3,
    img: "https://images.pexels.com/photos/6912818/pexels-photo-6912818.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Électronique",
  },
];

export const produitsPopulaires = [
  {
    id:1,
    img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
  },
  {
    id:2,
    img:"https://www.pngarts.com/files/1/Baby-Care-Products-PNG-High-Quality-Image.png",
  },
  {
    id:3,
    img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
  },
  {
    id:4,
    img:"https://www.pngarts.com/files/3/Clothing-Transparent-Background-PNG.png",
  },
  {
    id:5,
    img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
  },
  {
    id:6,
    img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
  },
  {
    id:7,
    img:"https://www.pngarts.com/files/3/Bicycle-Helmet-Transparent-Background-PNG.png",
  },
  {
    id:8,
    img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
  },
]